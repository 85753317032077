// import { baseURL } from 'config';
import React from 'react';
import { Trans } from 'react-i18next';
import { FactTypeValue, PaymentStatus, RecordRoles } from 'data/enums';
import Locale from 'data/enums/Locale';
import { t } from 'i18next';
import {
  clientsPath,
  modulesPath,
  organizationsPath,
  protocolsPath,
  questionnairesPath,
  reportsPath,
  templatesPath,
  therapistsPath,
} from './url/admin';

type LabelValueType = {
  label: string;
  value: string;
};

export const oldQITURL = 'https://tool.qitonline.be';
export const QITHelpURL = 'https://intercom.help/qit-online/nl/';
export const QITURL = 'https://www.qitonline.com/';
export const appUrl = 'https://app.qitonline.com';
export const stagingUrl = 'https://uat.qitonline.com';

export const extraTrajectoryURL = 'https://nl.qit-online.com/trajectories';
export const extraQuestionnaireURL = 'https://www.qit-online.com/questionnaires-pricing?lang=nl';
export const trajectoryTypesInfoURL =
  'https://intercom.help/qit-online/nl/articles/3704948-hoe-activeer-ik-een-traject#h_0faa5b4aee';
export const ReCAPTCHAKey = '6LcxSKMZAAAAALP8n6RQ87hdWy1PRwtA7lVWLU1E';
export const googleAnalytics = 'UA-89464105-12';
export const tagManager = 'GTM-P6KH8BH';
export const sentryDSN = 'https://912a3c3f4f0748c8a27a7ffccf943678@o363620.ingest.sentry.io/5375105';
export const pixelId = '204614148424209';

export const termsAndConditionsURL =
  'https://docs.google.com/document/d/1bDMutGWi4yw5t2wuCYPF_rDEYX9vyIML4Wsgrf3FhLk/edit?usp=sharing';
export const privacyPolicyURL =
  'https://docs.google.com/document/d/1NEzBvfImH0bFYjJoUdYjJozdhn9z7nJsGQUMIDmS0Iw/edit?usp=sharing';
export const securityPrinciplesURL =
  'https://docs.google.com/document/d/1Ip31jUo6ID6P65xZrh7rIcBiHTQL5fS4tUu4Tc5Fm-I/edit?usp=sharing';

export const importCalendarInfoURL = 'https://intercom.help/qit-online/nl/articles/6903023';
export const exportCalendarInfoURL = 'https://intercom.help/qit-online/nl/articles/6903158';
export const shareCalendarInfoURL = 'https://intercom.help/qit-online/nl/articles/6955485';

export const fetchLimit = 1000;
export const arrayResponseFallback = {
  items: [],
  count: 0,
  total: 0,
};

export const isMaintained = false;
export const intercomInitializeDelay = !isMaintained ? 3000 : 0;

export enum trajectoryStatuses {
  OTHER = 'Inactive - other reason',
  ACTIVE = 'Active',
  NO_SENSE = 'There is no sense in treatment',
  DIFFICULT = 'It is too difficult',
  ENDED = 'Ended',
  REFERRED = 'It is referred to other assistance',
}

export enum organizationStatuses {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  TRIAL = 'trial',
}

export enum organizationAccountType {
  REGULAR = 'regular',
  ENTERPRISE = 'enterprise',
}

export const sessionStatus = {
  1: 'Draft',
  2: 'Sent',
  3: 'Complete',
  4: 'Completed',
};

export enum questionnaireStatus {
  STATUS_UNSENT = 'unsent',
  STATUS_SENT = 'sent',
  STATUS_COMPLETE = 'complete',
}

export const signInErrors = {
  badCredentials: <Trans i18nKey="errors.badCredentials" />,
  suspendedUserError: <Trans i18nKey="errors.suspendedUserError" />,
  suspendedTherapistError: <Trans i18nKey="errors.suspendedTherapistError" />,
  noOrgMemberError: <Trans i18nKey="errors.noOrgMemberError" />,
  disabledOrgError: <Trans i18nKey="errors.disabledOrgError" />,
  emailAlreadyChanged: <Trans i18nKey="errors.emailAlreadyChanged" />,
};

export const endTrajectoryReasons = ['success', 'limit', 'agreement', 'faded', 'client', 'referred', 'other'];

export const SessionStatusEnum = {
  draft: 'draft',
  sent: 'sent',
  finished: 'finished',
  completed: 'completed',
  skipped: 'skipped',
};

export const eventType = {
  survey: 'survey',
  session: 'session',
  task: 'task',
  sync: 'sync',
  module: 'module',
};

export const interactionTypes = {
  interaction: 'Interaction',
  video_interaction: 'Video interaction',
  physical_interaction: 'Physical interaction',
  sync_interaction: 'Sync interaction',
};

export const recordManagers = [RecordRoles.OWNER, RecordRoles.VIEWER, RecordRoles.COLLABORATOR];

export const paymentStatusOptions = [
  { label: 'paymentStatusText.notPaidEmpty', value: PaymentStatus.NOT_PAID },
  { label: 'paymentStatusText.paidEmpty', value: PaymentStatus.PAID },
];

export const optionsMaritalStatus = [
  { value: 'single', label: 'single' },
  { value: 'withoutPartner', label: 'withoutPartner' },
  { value: 'withPartner', label: 'withPartner' },
  { value: 'withPartnerWithKids', label: 'withPartnerWithKids' },
  { value: 'childInASingleParentFamily', label: 'childInASingleParentFamily' },
  { value: 'childInAMultiParentFamily', label: 'childInAMultiParentFamily' },
  { value: 'twoFamilyChild', label: 'twoFamilyChild' },
  { value: 'inACenter', label: 'inACenter' },
  { value: 'inACareInstitution', label: 'inACareInstitution' },
  { value: 'homeless', label: 'homeless' },
  { value: 'widow', label: 'widow' },
  { value: 'others', label: 'others' },
  { value: 'newlyComposedFamily', label: 'newlyComposedFamily' },
  { value: 'coHousing', label: 'coHousing' },
  { value: 'inADorm', label: 'inADorm' },
];

export const optionsNatureTreatment = [
  { value: 'Coaching', label: 'coaching' },
  { value: 'Dagbehandeling', label: 'dayTreatment' },
  { value: 'Diagnostiek', label: 'diagnostics' },
  { value: 'Gezinstherapie', label: 'familyTherapy' },
  { value: 'Groepsbegeleiding', label: 'groupGuidance' },
  { value: 'Groepstherapie', label: 'groupTherapy' },
  { value: 'Individuele begeleiding', label: 'individualGuidance' },
  { value: 'Individuele therapie', label: 'individualTherapy' },
  { value: 'Ouderbegeleiding', label: 'parentalGuidance' },
  { value: 'Psychiatrische behandeling', label: 'psychiatricTreatment' },
  { value: 'Relatietherapie', label: 'relationshipTherapy' },
  { value: 'Residenti\u00eble behandeling', label: 'residentialTreatment' },
  { value: 'Supervisie', label: 'supervision' },
  { value: 'Thuisbegeleiding', label: 'homeAssistance' },
  { value: 'Speltherapie', label: 'playTherapy' },
  { value: 'Neuropsychologisch onderzoek', label: 'neuropsychologicalResearch' },
  { value: 'Bemiddeling', label: 'mediation' },
  { value: 'Perinatale counseling', label: 'perinatalCounseling' },
  { value: 'Rookstop', label: 'smokingCessation' },
  { value: 'Psycho-educatie', label: 'psychoEducation' },
  { value: 'Loopbaanbegeleiding', label: 'careerCounseling' },
  { value: 'EMDR-behandeling', label: 'EMDRTreatment' },
  { value: 'Sekstherapie', label: 'sexTherapy' },
];

export const optionsClinicalDisorders = [
  { value: 'Stoornissen in de kindertijd', label: 'childhoodDisorders' },
  {
    value: 'Delirium, dementie en amnestische en andere cognitieve stoornissen',
    label: 'cognitiveImpairments',
  },
  { value: 'Psychische stoornissen door een somatische aandoening', label: 'psychiatricDisorders' },
  { value: 'Aan een middel gebonden stoornissen', label: 'drugRelatedDisorders' },
  { value: 'Schizofrenie en andere psychotische stoornissen', label: 'psychoticDisorders' },
  { value: 'Stemmingsstoornissen', label: 'moodDisorders' },
  { value: 'Angststoornissen', label: 'anxietyDisorders' },
  { value: 'Somatoforme stoornis', label: 'somatoformDisorder' },
  { value: 'Nagebootste stoornissen', label: 'simulatedDisorders' },
  { value: 'Dissociatieve stoornissen', label: 'dissociativeDisorders' },
  { value: 'Seksuele stoornissen en genderidentiteitsstoornissen', label: 'genderIdentityDisorders' },
  { value: 'Eetstoornissen', label: 'eatingDisorders' },
  { value: 'Slaapstoornissen', label: 'sleepDisorders' },
  { value: 'Stoornissen in de impulsbeheersing', label: 'impulseControlDisorders' },
  { value: 'Aanpassingsstoornissen', label: 'adjustmentDisorders' },
  {
    value: 'Andere aandoeningen en problemen die een reden voor zorg kunnen zijn',
    label: 'reasonForConcern',
  },
  { value: 'Uitgesteld/geen diagnose', label: 'delayed' },
];

export const optionsPersonalityDisorders = [
  { value: 'Lichte zwakzinnigheid', label: 'slightDementia' },
  { value: 'Matige zwakzinnigheid', label: 'moderateDementia' },
  { value: 'Ernstige zwakzinnigheid', label: 'severeDementia' },
  { value: 'Diepe zwakzinnigheid', label: 'deepDementia' },
  { value: 'Zwakzinnigheid, ernst niet gespecificeerd', label: 'insanitySeverityNotSpecified' },
  { value: 'Paranoïde persoonlijkheidsstoornis', label: 'paranoidThePersonalityDisorder' },
  { value: 'Schizoïde persoonlijkheidsstoornis', label: 'schizoPersonalityDisorder' },
  { value: 'Schizotypische persoonlijkheidsstoornis', label: 'schizotypalPersonalityDisorder' },
  { value: 'Antisociale persoonlijkheidsstoornis', label: 'antisocialPersonalityDisorder' },
  { value: 'Borderline persoonlijkheidsstoornis', label: 'borderlinePersonalityDisorder' },
  { value: 'Theatrale persoonlijkheidsstoornis', label: 'theatricalPersonalityDisorder' },
  { value: 'Narcistische persoonlijkheidsstoornis', label: 'narcissisticPersonalityDisorder' },
  { value: 'Ontwijkende persoonlijkheidsstoornis', label: 'evasivePersonalityDisorder' },
  { value: 'Afhankelijke persoonlijkheidsstoornis', label: 'dependentPersonalityDisorder' },
  {
    value: 'Obsessieve – compulsieve persoonlijkheidsstoornis',
    label: 'obsessiveCompulsivePersonalityDisorder',
  },
  {
    value: 'Persoonlijkheidsstoornis NAO',
    label: 'NAOPersonalityDisorder',
  },
  {
    value: 'Uitgesteld / geen persoonlijkheidsstoornis',
    label: 'postponed',
  },
  {
    value: 'Zwakbegaafdheid (Bijkomende problemen die een reden voor zorg kunnen zijn)',
    label: 'poorGiftedness',
  },
];

export const optionsSomaticDisorders = [
  {
    value: 'Diagnose op As3 complex',
    label: 'diagnosisComplex',
  },
  {
    value: 'Diagnose op As3 enkelvoudig',
    label: 'diagnosisSingle',
  },
  {
    value: 'Geen of geen relevante diagnose op As3',
    label: 'noRelevantDiagnosis',
  },
];

export const optionsPsychosocialFactors = [
  {
    value: 'Problemen binnen de primaire steungroep',
    label: 'supportProblems',
  },
  {
    value: 'Problemen verbonden aan de sociale omgeving',
    label: 'environmentProblems',
  },
  {
    value: 'Studie/scholingsproblemen',
    label: 'studyProblems',
  },
  {
    value: 'Werkproblemen',
    label: 'workProblems',
  },
  {
    value: 'Woonproblemen',
    label: 'housingProblems',
  },
  {
    value: 'Financiële problemen',
    label: 'financialProblems',
  },
  {
    value: 'Problemen met de toegankelijkheid van gezondheidsdiensten',
    label: 'healthServicesProblems',
  },
  {
    value: 'Problemen met justitie/politie of met de misdaad',
    label: 'crimeProblems',
  },
  {
    value: 'Andere psychosociale en omgevingsproblemen',
    label: 'otherProblems',
  },
  {
    value: 'Geen diagnose/factor op As 4 aanwezig',
    label: 'noDiagnosis',
  },
];

export const optionsGAFScores = [
  {
    value: 'GAF score 0',
    label: 'GAFScore0',
  },
  {
    value: 'GAF score 1-10',
    label: 'GAFScore10',
  },
  {
    value: 'GAF score 11-20',
    label: 'GAFScore20',
  },
  {
    value: 'GAF score 21-30',
    label: 'GAFScore30',
  },
  {
    value: 'GAF score 31-40',
    label: 'GAFScore40',
  },
  {
    value: 'GAF score 41-50',
    label: 'GAFScore50',
  },
  {
    value: 'GAF score 51-60',
    label: 'GAFScore60',
  },
  {
    value: 'GAF score 61-70',
    label: 'GAFScore70',
  },
  {
    value: 'GAF score 71-80',
    label: 'GAFScore80',
  },
  {
    value: 'GAF score 81-90',
    label: 'GAFScore90',
  },
  {
    value: 'GAF score 91-100',
    label: 'GAFScore100',
  },
];

export const typeOfValueOptions: { label: string; value: string }[] = [
  {
    label: 'calculationResultLocale',
    value: FactTypeValue.CALCULATION,
  },
  {
    label: 'patientDetailsLocale',
    value: FactTypeValue.PATIENT,
  },
  {
    label: 'answerToQuestionLocale',
    value: FactTypeValue.QUESTION,
  },
  {
    label: 'textField',
    value: FactTypeValue.TEXT,
  },
  {
    label: 'normedView',
    value: FactTypeValue.NORM,
  },
  { label: 'sessionInformationLocale', value: FactTypeValue.QUESTIONNAIRE_CONTAINER },
];

export const sessionData = [
  {
    label: 'sequenceNumber',
    value: 'orderIndex',
  },
  {
    label: 'sessionNumber',
    value: 'measurement',
  },
  {
    label: 'sessionDate',
    value: 'startDate',
  },
  {
    label: 'invitationDate',
    value: 'createdAt',
  },
];

export const clientDataOptions = [
  {
    label: 'firstName',
    value: 'firstName',
  },
  {
    label: 'lastName',
    value: 'lastName',
  },
  {
    label: 'gender',
    value: 'gender',
  },
  {
    label: 'birthDate',
    value: 'birthday',
  },
  {
    label: 'nationality',
    value: 'nationality',
  },
  {
    label: 'lifeSituation',
    value: 'maritalStatus',
  },
  {
    label: 'levelOfEducation',
    value: 'education',
  },
  {
    label: 'activity',
    value: 'activity',
  },
  {
    label: 'natureTreatment',
    value: 'natureTreatment',
  },
  {
    label: 'startOfTreatment',
    value: 'startDate',
  },
];

export const contentOptions: LabelValueType[] = [
  {
    label: 'factsEnumeration',
    value: 'factsEnumeration',
  },
  {
    label: 'graphContent',
    value: 'graphContent',
  },
  {
    label: 'textField',
    value: 'text',
  },
  {
    label: 'tableContent',
    value: 'tableContent',
  },
];

export const period = [
  {
    label: 'currentSession',
    value: 'current',
  },
  {
    label: 'firstSession',
    value: 'first',
  },
  {
    label: 'differenceFirstSession',
    value: 'Difference between current and first',
  },
  {
    label: 'prevSession',
    value: 'previous',
  },
  {
    label: 'differencePrevSession',
    value: 'Difference between current and previous',
  },
];

export const memberRoles = [
  {
    label: <Trans i18nKey="partner" />,
    value: 'Partner',
  },
  {
    label: <Trans i18nKey="father" />,
    value: 'Father',
  },
  {
    label: <Trans i18nKey="stepFather" />,
    value: 'Stepfather',
  },
  {
    label: <Trans i18nKey="mother" />,
    value: 'Mother',
  },
  {
    label: <Trans i18nKey="stepMother" />,
    value: 'Stepmother',
  },
  {
    label: <Trans i18nKey="brother" />,
    value: 'Brother',
  },
  {
    label: <Trans i18nKey="stepBrother" />,
    value: 'Stepbrother',
  },
  {
    label: <Trans i18nKey="halfbrother" />,
    value: 'Halfbrother',
  },
  {
    label: <Trans i18nKey="sister" />,
    value: 'Sister',
  },
  {
    label: <Trans i18nKey="stepSister" />,
    value: 'Stepsister',
  },
  {
    label: <Trans i18nKey="halfsister" />,
    value: 'Halfsister',
  },
  {
    label: <Trans i18nKey="son" />,
    value: 'Son',
  },
  {
    label: <Trans i18nKey="daughter" />,
    value: 'Daughter',
  },
  {
    label: <Trans i18nKey="other" />,
    value: 'Other',
  },
];

export const accToType = {
  1: 'regular',
  2: 'enterprise',
};

export const statusToType = {
  0: 'Inactive',
  1: 'Active',
};

export const localeToLang = {
  [Locale.ENGLISH]: 'english',
  [Locale.DUTCH]: 'dutch',
  [Locale.FRENCH]: 'french',
};

export const libraryOptions = [
  { name: 'questionnaire_plural', link: questionnairesPath, icon: 'questionnaire', index: 3 },
  { name: 'module_plural', link: modulesPath, icon: 'module', index: 5 },
  { name: 'sequence_plural', link: protocolsPath, icon: 'trajectory', index: 7 },
  { name: 'template_plural', link: templatesPath, icon: 'fileText', index: 4 },
  { name: 'report_plural', link: reportsPath, icon: 'report', index: 6 },
];

export const usersOptions = [
  { name: 'organization_plural', link: organizationsPath, icon: 'home', index: 1 },
  { name: 'therapist_plural', link: therapistsPath, icon: 'therapist', index: 2 },
  { name: 'patient_plural', link: clientsPath, icon: 'users', index: 3 },
];

export const oneSecondInMillisecond = 1000;

export const normedViewOptions = [
  { label: 'text', value: 'text' },
  { label: 'name', value: 'name' },
  { label: 'score', value: 'score' },
];

export const forbiddenErrorCode = 403;
export const nonFoundErrorCode = 404;

export const isResetPasswordDisabledKey = 'isResetPasswordDisabled';
export const fiveMinutesInMilliseconds = oneSecondInMillisecond * 60 * 5;

export const userTypeToRelation = {
  [RecordRoles.AFFILIATE]: 'recordRoles.affiliate',
  [RecordRoles.CLIENT]: 'recordRoles.client',
  [RecordRoles.OWNER]: 'therapist',
  [RecordRoles.VIEWER]: 'therapist',
  [RecordRoles.COLLABORATOR]: 'therapist',
};
