export const MODE = import.meta.env.MODE;

export const PROD = import.meta.env.MODE === 'production';
export const STAGING = import.meta.env.MODE === 'staging';
export const DEV = !PROD && !STAGING;
// export const __TEST__ = import.meta.env.PROJECT_ENV && ['test', 'test-ci'].includes(import.meta.env.PROJECT_ENV);

export const TEST = import.meta.env.MODE === 'test';

export const QIT_API_BASE_URL = import.meta.env.QIT_API_BASE_URL;

export const QIT_INTERCOM_VERIFICATION_SECRET = import.meta.env.QIT_INTERCOM_VERIFICATION_SECRET;

export const QIT_INTERCOM_API_KEY = import.meta.env.QIT_INTERCOM_API_KEY;

export const QIT_SENTRY = import.meta.env.QIT_SENTRY;

export const QIT_DEV_CYCLE_SDK_KEY = import.meta.env.QIT_DEV_CYCLE_SDK_KEY;
