import { EventTypeEnum } from 'data/enums/EventTypes';
import {
  Note,
  ArrayResponseType,
  CreateNoteBody,
  NoteResponse,
  BaseFetchListParams,
  PinNoteBody,
  InteractionActivityEntity,
} from 'data/types';
import { notesKeys, interactionActivitiesKeys, sessionKeys, activitiesKeys, trajectoryKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'qs';
import { NotesSortKeys, NotesType, SortDirection } from 'data/enums';
import { AxiosError } from 'axios';

import * as api from '../actions-query';

interface CreateNoteParams {
  body: api.NoteBodyToEdit;
}

interface EditNoteParams extends CreateNoteParams {
  id: string;
}

export const interactionTypeToQuery = {
  [EventTypeEnum.interactionPhysical_interaction_activity]: interactionActivitiesKeys,
  [EventTypeEnum.interactionSync_interaction_activity]: interactionActivitiesKeys,
  [EventTypeEnum.interactionVideo_interaction_activity]: interactionActivitiesKeys,
};

export const eventTypeToQuery = {
  ...interactionTypeToQuery,
  [EventTypeEnum.session]: sessionKeys,
  [EventTypeEnum.simple_task_activity]: activitiesKeys,
  [EventTypeEnum.module_task_activity]: activitiesKeys,
};

export const useRecordNotes = (recordId: string, searchParams: BaseFetchListParams) => {
  const query = queryString.stringify(searchParams);
  const queryClient = useQueryClient();

  return useQuery<ArrayResponseType<Note>, AxiosError>(
    notesKeys.record(recordId),
    () => api.fetchRecordNotes(recordId, query),
    {
      placeholderData: () => queryClient.getQueryData(notesKeys.record(recordId)),
    },
  );
};

export const useTrajectoryNotes = (trajectoryId: string) =>
  useQuery<Note[], AxiosError>(notesKeys.trajectory(trajectoryId), () => api.fetchTrajectoryNotes(trajectoryId), {
    enabled: !!trajectoryId,
  });

export const useSessionNotes = (sessionId: string) =>
  useQuery<Note[], AxiosError>(notesKeys.session(sessionId), () => api.fetchSessionNotes(sessionId));

export const useDeleteNote = (
  trajectoryId?: string,
  recordId?: string,
  eventId?: string,
  eventType?: EventTypeEnum,
  noteType?: NotesType,
  sortDirection?: SortDirection,
): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((id) => api.deleteNote(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(notesKeys.trajectory(trajectoryId));
      queryClient.invalidateQueries(notesKeys.record(recordId, noteType, sortDirection), {
        refetchType: 'all',
      });
      queryClient.invalidateQueries(trajectoryKeys.detail(trajectoryId));

      if (eventId && eventType) {
        queryClient.invalidateQueries(eventTypeToQuery[eventType].detail(eventId));
      }
    },
  });
};

export const useEditNote = (
  trajectoryId?: string,
  recordId?: string,
  eventId?: string,
  eventType?: EventTypeEnum,
  noteType?: NotesType,
  sortDirection?: SortDirection,
): UseMutationResult<unknown, any, EditNoteParams, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, body }) => api.editNote(id, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(notesKeys.trajectory(trajectoryId));
      queryClient.invalidateQueries(notesKeys.record(recordId, noteType, sortDirection), {
        refetchType: 'all',
      });

      if (eventId && eventType) {
        queryClient.invalidateQueries(eventTypeToQuery[eventType].detail(eventId));
      }
    },
  });
};

export const useCreatePersonalNote = (
  recordId: string,
  sortDirection: SortDirection,
): UseMutationResult<NoteResponse, AxiosError, CreateNoteBody, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createPersonalNote(recordId, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(notesKeys.record(recordId, NotesType.PERSONAL, sortDirection));
    },
  });
};

export const useCreateInteractionNote = (
  interactionId: string,
  eventType?: EventTypeEnum,
): UseMutationResult<NoteResponse, AxiosError, CreateNoteBody, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createInteractionNote(interactionId, values), {
    onSuccess: () => {
      if (eventType) {
        queryClient.invalidateQueries(interactionTypeToQuery[eventType].detail(interactionId));
      }
    },
  });
};

export const useCreateSessionNote = (
  sessionId: string,
  trajectoryId: string,
  recordId: string,
): UseMutationResult<NoteResponse, AxiosError, CreateNoteBody, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createSessionNote(sessionId, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionKeys.detail(sessionId));
      queryClient.invalidateQueries(trajectoryKeys.detail(trajectoryId));
      queryClient.invalidateQueries(notesKeys.record(recordId, NotesType.TRAJECTORY, SortDirection.desc), {
        refetchType: 'all',
      });
    },
  });
};

export const useCreateActivityNote = (
  activityId: string,
  trajectoryId: string,
  recordId: string,
): UseMutationResult<NoteResponse, AxiosError, CreateNoteBody, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createActivityNote(activityId, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(activitiesKeys.detail(activityId));
      queryClient.invalidateQueries(interactionActivitiesKeys.detail(activityId));
      queryClient.invalidateQueries(trajectoryKeys.detail(trajectoryId));
      queryClient.invalidateQueries(notesKeys.record(recordId, NotesType.TRAJECTORY, SortDirection.desc), {
        refetchType: 'all',
      });
    },
  });
};

export const usePinNote = (
  recordId: string,
  sortDirection: SortDirection,
): UseMutationResult<NoteResponse, AxiosError, { userId: string; body: PinNoteBody }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ userId, body }) => api.pinNote(userId, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(notesKeys.record(recordId, NotesType.PERSONAL, sortDirection));
    },
  });
};

export const useUnpinNote = (
  recordId: string,
  sortDirection: SortDirection,
): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((favouriteId) => api.unpinNote(favouriteId), {
    onSuccess: () => {
      queryClient.invalidateQueries(notesKeys.record(recordId, NotesType.PERSONAL, sortDirection));
    },
  });
};

export const useNotesWithAttachments = (recordId: string, query: any, type: NotesType) => {
  const stringifiedQuery = queryString.stringify(query);
  return useQuery<ArrayResponseType<InteractionActivityEntity>, AxiosError>(
    notesKeys.record(recordId, type, query.sort[NotesSortKeys.START_DATE]),
    () => api.fetchNotesWithAttachments(recordId, stringifiedQuery),
    {
      enabled: !!recordId,
    },
  );
};
