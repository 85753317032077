import { api } from 'data';
import { ImportCalendarFromUrl, CalendarType, PersonalSlot, CalendarEvent, EditCalendarValues } from 'data/types';

export const importCalendar = (userId: string, body: ImportCalendarFromUrl): Promise<CalendarType> =>
  api.post(`/calendars/users/${userId}/import`, body);

export const deleteExternalCalendar = (calendarId: string): Promise<void> => api.delete(`/calendars/${calendarId}`);

export const getExternalCalendar = (calendarId: string): Promise<CalendarType> => api.get(`/calendars/${calendarId}`);

export const fetchCalendarURL = (calendarId: string): Promise<string> => api.get(`/calendars/${calendarId}/export`);

export const exportCalendar = (calendarId: string): Promise<string> => api.post(`/calendars/${calendarId}/export`);

export const deleteExportCalendar = (calendarId: string): Promise<void> =>
  api.delete(`/calendars/${calendarId}/export`);

export const fetchCalendars = (userId: string): Promise<CalendarType[]> => api.get(`/calendars/users/${userId}`);

export const fetchEvents = (userId: string, params: string): Promise<CalendarEvent[]> =>
  api.get(`/calendars/users/${userId}/slots?${params}`);

export const fetchExternalEvents = (calendarId: string, params: string): Promise<CalendarEvent[]> =>
  api.get(`/calendars/${calendarId}/slots?${params}`);

export const fetchSlot = (slotId: string): Promise<CalendarEvent> => api.get(`/calendars/slots/${slotId}`);

export const createSlot = (calendarId: string, body: PersonalSlot): Promise<CalendarEvent> =>
  api.post(`/calendars/${calendarId}/slots`, body);

export const patchCalendar = (calendarId: string, body: EditCalendarValues): Promise<CalendarType> =>
  api.patch(`/calendars/${calendarId}`, body);

export const shareCalendar = (calendarId: string, body: { users: string[] }): Promise<CalendarType> =>
  api.patch(`calendars/${calendarId}/share`, body);

export const removeShareCalendarWithUser = (calendarId: string, userId: string): Promise<CalendarType> =>
  api.delete(`/calendars/${calendarId}/share/users/${userId}`);

export const deleteSlot = (slotId: string): Promise<void> => api.delete(`/calendars/slots/${slotId}`);

export const patchSlot = (slotId: string, body: PersonalSlot): Promise<CalendarEvent> =>
  api.patch(`/calendars/slots/${slotId}`, body);
